
import { Options, Vue } from "vue-class-component";
import Header from "@/components/Header/Header.vue";

@Options({
  components: {
    Header,
  },
  data() {
    return {
      mobile: false,
      show: false,
      showOffer: false,
      showPortfolio0: false,
      showPortfolio1: false,
      showPortfolio2: false,
      showPortfolio3: false,
      showPortfolio4: false,
      showPortfolio5: false,
      showPortfolio6: false,
      scrollPosition: 0,
      showImagePopUp: false,
      imageToShow: "../assets/www.visithasslo.se_.png",
      captionToShow: "Visit Hasslö landing page",
      zoomModalImage: false,
    };
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    showImage(url: string, caption: string) {
      this.imageToShow = url;
      this.captionToShow = caption;
      this.showImagePopUp = true;
      document.body.style.overflow = "hidden";
    },
    hideImage(event: Event) {
      if (event) {
        if (event.target) {
          if (
            !(event.target as HTMLElement).className.includes(
              "amj-modal showModal"
            ) &&
            !(event.target as HTMLElement).className.includes("close-modal")
          ) {
            return;
          }
        }
      }

      this.showImagePopUp = false;
      document.body.style.overflow = "auto";
      this.zoomModalImage = false;
    },
    zoomImage() {
      this.zoomModalImage = !this.zoomModalImage;
    },
    handleScroll() {
      const totalHeight =
        document.documentElement.scrollHeight -
        document.documentElement.scrollHeight * 0.15;
      const percentageHeight = (scrollY / totalHeight) * 100;
      this.scrollPosition = percentageHeight;
      this.mobile = window.innerWidth <= 800;

      if (this.mobile) {
        this.show = true;
        this.showOffer = true;
        this.showPortfolio6 = true;
        this.showPortfolio5 = true;
        this.showPortfolio4 = true;
        this.showPortfolio3 = true;
        this.showPortfolio2 = true;
        this.showPortfolio1 = true;
      }

      if (this.scrollPosition > 6) {
        this.show = true;
      }
      if (this.scrollPosition > 16) {
        this.showOffer = true;
      }
      if (this.scrollPosition > 30) {
        this.showPortfolio6 = true;
      }
      if (this.scrollPosition > 40) {
        this.showPortfolio5 = true;
      }
      if (this.scrollPosition > 50) {
        this.showPortfolio4 = true;
      }
      if (this.scrollPosition > 60) {
        this.showPortfolio3 = true;
      }
      if (this.scrollPosition > 70) {
        this.showPortfolio2 = true;
      }
      if (this.scrollPosition > 80) {
        this.showPortfolio1 = true;
      }
      /* if (this.scrollPosition > 92) {
        this.showPortfolio0 = true;
      } */
    },
  },
})
export default class HomeView extends Vue {}
