import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import QvantelView from "../views/QvantelView.vue";
import QvantelProtoView from "../views/QvantelProtoView.vue";
import DensitetView from "../views/DensitetView.vue";
import VisitHassloView from "../views/VisitHasslo.vue";
import EricssonView from "../views/EricssonView.vue";
import ExpertpoolenView from "../views/ExpertpoolenView.vue";
/* import PortfilioView from "../views/PortfolioView.vue"; */

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { title: "UX/UI Designer - André Johansson" },
  },
  {
    path: "/qvantel",
    name: "qvantel",
    component: QvantelView,
    meta: { title: "Sales & CRM Platform - André Johansson" },
  },
  {
    path: "/qvantel-development",
    name: "qvantel-dev",
    component: QvantelProtoView,
    meta: { title: "Prototyping - André Johansson" },
  },
  {
    path: "/densitet",
    name: "densitet",
    component: DensitetView,
    meta: { title: "densitet.se - André Johansson" },
  },
  {
    path: "/visit-hasslo",
    name: "visit-hasslo",
    component: VisitHassloView,
    meta: { title: "Visit Hasslö - André Johansson" },
  },
  {
    path: "/ericsson",
    name: "ericsson",
    component: EricssonView,
    meta: { title: "Lexie - André Johansson" },
  },
  {
    path: "/expertpoolen",
    name: "expertpoolen",
    component: ExpertpoolenView,
    meta: { title: "Expertpoolen - André Johansson" },
  },
  /*  {
    path: "/test",
    name: "test",
    component: PortfilioView,
    meta: { title: "André Johansson" },
  }, */
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        top: 0,
        behavior: "smooth",
      };
    }
    return { top: 0, behavior: "smooth" };
  },
});

router.beforeEach((to, from, next) => {
  (document.title as unknown) = to.meta.title;
  next();
});

export default router;
