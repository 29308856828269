
import { Options, Vue } from "vue-class-component";
import HeaderSmall from "@/components/Header/HeaderSmall.vue";

@Options({
  components: {
    HeaderSmall,
  },
})
export default class EricssonView extends Vue {}
