
import { Options, Vue } from "vue-class-component";
import HeaderSmall from "@/components/Header/HeaderSmall.vue";

@Options({
  components: {
    HeaderSmall,
  },
  data() {
    return {
      showImagePopUp: false,
      imageToShow: "../assets/expertpoolen.se_.png",
      captionToShow: "Expertpoolen landing page",
      zoomModalImage: false,
    };
  },
  methods: {
    showImage(url: string, caption: string) {
      this.imageToShow = url;
      this.captionToShow = caption;
      this.showImagePopUp = true;
      document.body.style.overflow = "hidden";
    },
    hideImage(event: Event) {
      if (event) {
        if (event.target) {
          if (
            !(event.target as HTMLElement).className.includes(
              "amj-modal showModal"
            ) &&
            !(event.target as HTMLElement).className.includes("close-modal")
          ) {
            return;
          }
        }
      }

      this.showImagePopUp = false;
      document.body.style.overflow = "auto";
      this.zoomModalImage = false;
    },
    zoomImage() {
      this.zoomModalImage = !this.zoomModalImage;
    },
  },
})
export default class DensitetView extends Vue {}
